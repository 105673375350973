import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fill-height":"","fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"xs":"12","sm":"10","md":"6","lg":"5"}},[_c(VCard,{staticClass:"elevation-12"},[_c(VRow,[_c(VCardTitle,{staticClass:"cardtext",attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"headline"},[_vm._v("Welcome, "+_vm._s(_vm.firstName)+" "+_vm._s(_vm.lastName))])])]),_c(VSpacer),_c(VCardActions,{staticClass:"logout"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.logout}},[_vm._v("log out")])],1)],1),_c(VDivider,{staticClass:"ma-4"}),_c(VCardTitle,{staticClass:"headline"},[_vm._v("Last views")]),_c(VDataTable,{attrs:{"items":_vm.routes,"hide-default-footer":"","headers":_vm.headers,"sort-by2":"when","sort-desc2":""},scopedSlots:_vm._u([{key:"item.path",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":item.path}},[_vm._v(_vm._s(item.path))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }